<template>
  <div>
    <Breadcrumbs />
    <section class="customer-service">
      <div class="container">
        <div class="row">
          <div class="col col-12 col-lg-9">
            <cmsBlockSimple
              v-if="typeof termsAndConditionCms == 'object'"
              :identifier="termsAndConditionCms.identifier"
            />
          </div>
          <div class="col col-12 col-lg-3">
            <cmsBlockSimple
              v-if="typeof customerServiceImgCms == 'object'"
              :identifier="customerServiceImgCms.identifier"
            />
            <cmsBlockSimple
              v-if="typeof contactInfoCms == 'object'"
              :identifier="contactInfoCms.identifier"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "../components/common/Breadcrumbs.vue";
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";

export default {
  components: {
    Breadcrumbs,
    cmsBlockSimple,
  },
  computed: {
    termsAndConditionCms() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "terms_and_condition"
      );
    },
    customerServiceImgCms() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "customer_service_image"
      );
    },
    contactInfoCms() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "customer_service_contact_info"
      );
    },
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "title",
          content: this.$t("terms_and_condtion"),
        },
        { name: "keywords", content: this.$t("terms_and_condtion") },
        { name: "description", content: this.$t("terms_and_condtion") },
        {
          property: "og:title",
          content: this.$t("terms_and_condtion"),
        },
        {
          property: "og:description",
          content: this.$t("terms_and_condtion"),
        },
        {
          name: "twitter:title",
          content: this.$t("terms_and_condtion"),
        },
        {
          name: "twitter:description",
          content: this.$t("terms_and_condtion"),
        },
      ],
      title: this.$t("terms_and_condtion"),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/base/scss/variables.scss";
.terms-condition {
  h4 {
    font-size: 22px;
    font-weight: 700;
  }
  .desc {
    font-size: 14px;
  }
}

.contact-info {
  ul {
    margin: 0;
    margin-top: 30px;
    li {
      display: flex;
      margin-bottom: 10px;

      .icon {
        width: 35px;
        height: 35px;
        border: 1px solid $primaryColor;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 15px;
        color: $primaryColor;

        svg {
          fill: $primaryColor;
          height: 20px;
        }
      }
      .details {
        font-size: 14px;
        .title {
          font-weight: 600;
        }
      }
    }
  }
}
</style>
